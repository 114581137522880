<template>
	<div>
		<!-- 头部logo -->
		<headLogo @navigation="navigation"></headLogo>
		
		<!-- 头部导航 -->
		<navigation :headTitle="headTitle" :headImg="headImg" style="margin-top:96px;"></navigation>
		
		<!-- 列表数据 -->
		<div class="notices">
			<div class="notices-list" v-for="(item,id) in currentPageData" :key="id">
				<el-row class="notices-list-extend">
					<el-col :span="6">
						<el-image :src='item.pictureUrl' class="notices-listimg"></el-image>
					</el-col>
					<el-col :span="15" class="notices-listdata">
						<el-row class="notices-listtitle">
							{{item.title}}
						</el-row>
						<el-row class="notices-listmessage" v-html="item.bodyContent">
							{{item.bodyContent}}
						</el-row>
					</el-col>
					<el-col :span="3">
						<div class="notices-date">
							<el-row class="notices-year">{{item.year}}</el-row>
							<el-row class="notices-time">{{item.time}}</el-row>
							<el-row class="notices-month">{{item.month}}</el-row>
						</div>
						<div class="notices-buttons">
							<button class="notices-button" @click="noticesDetails(item)">Learn More</button>
						</div>
					</el-col>
				</el-row>
			</div>
			<el-row class="limit">
				<el-pagination 
					background
					@current-change="currentChange"
					@prev-click="prevPage"
					@next-click="nextPage"
					:page-size="pageSize"
					:total="total"
					layout="total, sizes, prev, pager, next,jumper">
				</el-pagination>
			</el-row>
		</div>
		
		<!-- 菜单导航 -->
		<meauDialog :meauValue="navigationvalue" @meauFn="menu"></meauDialog>
		
		<!-- 底部导航 -->
		<bottomNavigation></bottomNavigation>
	</div>
</template>

<script>
import { selectContentListById , contentList } from '@/api/index.js'
	
// 头部组件
import headLogo from '@/components/headLogo.vue'
// 头部导航
import navigation from '@/components/navigation.vue'
// 底部导航
import bottomNavigation from '@/components/bottomNavigation.vue'
// 菜单导航
import meauDialog from '@/components/meauDialog.vue'
export default
{
	data()
	{
		return{
			// 头部导航标题
			headTitle:'BREAKING NEWS & NOTICES',
			// 头部导航图片
			headImg:'/index/list.png',
			// 导航值
			navigationvalue:false,
			
			// 分页
			listLoading: true,
			totalPage:1,
			currentPage:1,
			pageSize:10,
			currentPageData:[],
			
			// 公告数据
			list:[],
			
			// 总共页数
			page:1,
			// 当前页数
			currentPage:1,
			// 总条数
			total:0,
		}
	},
	components:
	{
		headLogo,
		navigation,
		bottomNavigation,
		meauDialog
	},
	created()
	{
		this.getNoticesList()
	},
	methods:
	{
		// 头部传来的数据
		navigation(v)
		{
			this.navigationvalue=v;
			console.log(this.navigationvalue,'头部传递菜单导航')
		},
		// 遮罩层传回来的数据
		menu(v)
		{
			this.navigationvalue=v;
		},
		
		// 获取公告数据
		getNoticesList()
		{
			selectContentListById(
			{
			  chnanelType: 1,
			  isIndex: 0,
			  moduleType: 'MODULE_BRESAKING'
			})
			.then((response)=>
			{
				if(response.data.data=='')
				{
					this.$router.push(
					{
						path:'/error',
						item:2,
					})
				}
				else
				{
					this.list=response.data.data;
					this.total=response.data.data.length;
					response.data.data.map((v)=>
					{
						v.pulish=v.publish.split('-')
						if(v.pulish[1]=='01')
						{
							v.pulish[1]='JAN'
						}
						else if(v.pulish[1]=='02')
						{
							v.pulish[1]='FEB'
						}
						else if(v.pulish[1]=='03')
						{
							v.pulish[1]='MAR'
						}
						else if(v.pulish[1]=='04')
						{
							v.pulish[1]='APR'
						}
						else if(v.pulish[1]=='05')
						{
							v.pulish[1]='MAY'
						}
						else if(v.pulish[1]=='06')
						{
							v.pulish[1]='JUN'
						}
						else if(v.pulish[1]=='07')
						{
							v.pulish[1]='JUL'
						}
						else if(v.pulish[1]=='08')
						{
							v.pulish[1]='AUG'
						}
						else if(v.pulish[1]=='09')
						{
							v.pulish[1]='SEP'
						}
						else if(v.pulish[1]=='10')
						{
							v.pulish[1]='OCT'
						}
						else if(v.pulish[1]=='11')
						{
							v.pulish[1]='NOV'
						}
						else if(v.pulish[1]=='12')
						{
							v.pulish[1]='DEC'
						}
						v.year=v.pulish[0];
						v.month=v.pulish[1];
						v.time=v.pulish[2];
					})
					// 分页
					this.list=response.data.data;
					this.listLoading = false;
					this.totalPage=Math.ceil(this.list.length / this.pageSize);
					this.totalPage = this.totalPage == 0 ? 1 : this.totalPage;
					this.getCurrentPageData();
				}
			})	
		},
		
		// 资讯详情
		noticesDetails(item)
		{
			localStorage.setItem('Details',JSON.stringify(item));
			this.$router.push(
			{
				path:'/noticesDetails',
				query:
				{
					item:item,
					headTitle:'BREAKING NEWS & NOTICES',
					headImg:'/index/list.png',
				}
			})
		},
		// 分页获取数据
		getCurrentPageData() 
		{
			let begin = (this.currentPage - 1) * this.pageSize;
			let end = this.currentPage * this.pageSize;
			this.currentPageData = this.list.slice(
				begin,
				end
			);
		},
		//上一页
		prevPage()
		{
			if (this.currentPage == 1) 
			{
				return false;
			}
			else
			{
				this.currentPage--;
				this.getCurrentPageData();
			}
		},
		// 下一页
		nextPage()
		{
			if (this.currentPage == this.totalPage) 
			{
				return false;
			}
			 else 
			{
				this.currentPage++;
				this.getCurrentPageData();
			}
		},
		// 获取当前页数
		currentChange(current)
		{
			this.currentPage=current
			this.getCurrentPageData()
		},
	}
}

</script>

<style scoped>
	.notices{
		width: 1170px;
		margin: 0 auto;
	}
	.notices-list{
		margin-top: 20px;
		height: 240px;
		border: solid 3px #f5f5f5;
		position: relative;
	}
	.notices-listimg{
		width: 320px;
		height: 240px;
	}
	.notices-listdata{
		height: 240px;
		padding-left: 4%;
		padding-right: 2%;
		text-align: left;
	}
	.notices-listtitle{
		height: 70px;
		margin-top: 40px;
		font-size: 30px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.notices-listmessage{
		height: 40px;
		font-size: 18px;
		margin-top: 35px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.notices-date{
		width: 50px;
		background-color: #F0EDF0;
		position: absolute;
		top: -10px;
		right: 20px;
	}
	.notices-year{
		font-size: 12px;
		margin-top: 10px;
	}
	.notices-month{
		font-size: 14px;
		margin-top: 5px;
	}
	.notices-time{
		font-size: 25px;
		margin-top: 5px;
	}
	.limit{
		margin-top: 30px;
	}
	.notices-list :hover{
		box-shadow: 1px 1px 10px #999;
	}
	.notices-list:hover .notices-button{
		width: 100%;
		height: 40px;
		border-radius: 50px;
		background-color: #FECF00;
		border: none;
		color: #505050 !important;
	}
	.notices-list-extend :hover{
		box-shadow: none;
	}
	.notices-buttons{
		width: 150px;
		height: 40px;
		position: absolute;
		bottom: 10px;
		right: 10px;
	}
	.notices-button{
		width: 100%;
		height: 40px;
		border-radius: 50px;
		background-color: white;
		border: none;
		color: white !important;
	}
	.limit /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
	  background-color: #FDD000 !important;
	}
</style>